<template>
  <div>
    <h2>Ini buat test yaa</h2>
		<template v-if="(leftFinger.length >= 4) && (rightFinger.length >= 4)">
		<button @click.prevent="enroll">
			enroll
		</button>
		</template>
        
        <button @click.prevent="startVerify">
			start verify
		</button>
        <button v-if="verifyFinger" @click.prevent="verify">
			verify data
		</button>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
export default {
  extends: GlobalVue,
	data(){
		return {
			leftFinger: [],
			rightFinger: [],
      verifyFinger: '',
		}
	},
  methods: {
    initAll(){
      let self = this
      let currentFormat = window.Fingerprint.SampleFormat.Intermediate
    
      let FingerprintSdkTest = (function () {
        function FingerprintSdkTest() {
            let _instance = this
            this.operationToRestart = null
            this.acquisitionStarted = false
            // instantiating the fingerprint sdk here
            this.sdk = new window.Fingerprint.WebApi
						console.log(this.sdk)

            this.sdk.onDeviceConnected = function (e) {
                console.log("connect")
            }
            this.sdk.onDeviceDisconnected = function (e) {
                // Detects if device gets disconnected - provides deviceUid of disconnected device
                console.log("disconnect")
            }
            this.sdk.onCommunicationFailed = function (e) {
                // Detects if there is a failure in communicating with U.R.U web SDK
                console.log("failure")
            }
            this.sdk.onSamplesAcquired = function (s) {
                self.storeSample(s)
            }
            this.sdk.onQualityReported = function (e) {
                // Quality of sample acquired - Function triggered on every sample acquired
                //document.getElementById("qualityInputBox").value = window.Fingerprint.QualityCode[(e.quality)]
            }
        }

        // this is were finger print capture takes place
        FingerprintSdkTest.prototype.startCapture = function () {
            if (this.acquisitionStarted) // Monitoring if already started capturing
                return
            let _instance = this
            this.operationToRestart = this.startCapture
            this.sdk.startAcquisition(currentFormat, "").then(function () {
                _instance.acquisitionStarted = true

                //Disabling start once started
                //disableEnableStartStop()

            }, function (error) {
              console.log(error)
            })
        }
        
        FingerprintSdkTest.prototype.stopCapture = function () {
            if (!this.acquisitionStarted) //Monitor if already stopped capturing
                return
            let _instance = this
            this.sdk.stopAcquisition().then(function () {
                _instance.acquisitionStarted = false

                //Disabling stop once stopped
                //disableEnableStartStop()

            }, function (error) {
              console.log(error)
            })
        }

        FingerprintSdkTest.prototype.getInfo = function () {
            let _instance = this
            return this.sdk.enumerateDevices()
        }

        FingerprintSdkTest.prototype.getDeviceInfoWithID = function (uid) {
            let _instance = this
            return  this.sdk.getDeviceInfo(uid)
        }
        return FingerprintSdkTest
      })()

      class Reader{
          constructor(){
              this.reader = new FingerprintSdkTest()
              this.selectFieldID = null
              this.currentStatusField = null
              /**
               * @type {Hand}
               */
              this.currentHand = null
          }

          readerSelectField(selectFieldID){
              this.selectFieldID = selectFieldID
          }

          setStatusField(statusFieldID){
              this.currentStatusField = statusFieldID
          }

          displayReader(){
              let readers = this.reader.getInfo()  // grab available readers here
              let id = this.selectFieldID
              let selectField = document.getElementById(id)
              selectField.innerHTML = `<option>Select Fingerprint Reader</option>`
              readers.then(function(availableReaders){  // when promise is fulfilled
                  if(availableReaders.length > 0){
                      for(let reader of availableReaders){
                          selectField.innerHTML += `<option value="${reader}" selected>${reader}</option>`
                      }
                  }
                  else{
                      console.log("Please Connect the Fingerprint Reader")
                  }
              })
          }
      }

      class Hand{
          constructor(){
              this.id = 0
              this.index_finger = []
              this.middle_finger = []
          }

          addIndexFingerSample(sample){
              this.index_finger.push(sample)
          }

          addMiddleFingerSample(sample){
              this.middle_finger.push(sample)
          }

          generateFullHand(){
              let id = document.getElementById("userID").value
              let index_finger = this.index_finger
              let middle_finger = this.middle_finger
              return JSON.stringify({id, index_finger, middle_finger})
          }
      }

      let myReader = new Reader()

      myReader.currentHand = new Hand()
      myReader.reader.startCapture()
    },
    storeSample(sample){
        let samples = JSON.parse(sample.samples)
        let sampleData = samples[0].Data
        
        // enroll
        if(this.leftFinger.length < 4 && !this.rightFinger.length){
            this.leftFinger.push(sampleData)
        }else if(this.rightFinger.length < 4 && this.leftFinger.length == 4){
            this.rightFinger.push(sampleData)
        }

        // verify
        this.verifyFinger = sampleData

        console.log("ini samples Data",sampleData)
        console.log(this.leftFinger)
        console.log(this.rightFinger)
    },
    enroll(){
        console.log("submit here")
        let id = this.user.id
        let leftFinger = this.leftFinger
        let rightFinger = this.rightFinger
        let data = {id, leftFinger, rightFinger}
        
        let postData = {
            data : data,
            id: this.user.id,
            type : "enroll"
        }

        Gen.apiRest(
                "/do/"+'FingerPrint', 
                {
                    data: postData
                }, "POST"
        ).then(res=>{
            console.log(res)
        })

    },
    startVerify(){
        // this.leftFinger = []
        // this.rightFinger =  []
        this.verifyFinger =  ''
    },
    verify(){
        let id = 112
        let verifyFinger = this.verifyFinger
        let data = {id, verifyFinger}
        
        let postData = {
            data : data,
            id: 112,
            type : "verify"
        }

        Gen.apiRest(
            "/do/"+'FingerPrint', 
            {
                data: postData
            }, "POST"
        ).then(res=>{
            console.log(res)
        })
    }
  },
  mounted() {
    this.initAll()
  },
}
</script>